import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/jenkins/workspace/canvas-site-deploy/canvas-site/src/components/MDXLayout.tsx";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const InternalContent = makeShortcode("InternalContent");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2 {...{
      "id": "canvas-versions"
    }}>{`Canvas Versions`}</h2>
    <InternalContent mdxType="InternalContent">
      <p><strong parentName="p">{`Note:`}</strong>{` Starting with the v9 Canvas Release, we will be introducing versioned Figma releases and
an updated upgrade guide format. Each upgrade guide will have tabs for the following sections:
Overview, Developer Upgrade Guide, and Designer Upgrade Guide.`}</p>
    </InternalContent>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/help/upgrade-guides/canvas-v11-upgrade-guide"
        }}>{`Canvas v11 Upgrade Guide`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/help/upgrade-guides/canvas-v10-upgrade-guide"
        }}>{`Canvas v10 Upgrade Guide`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/help/upgrade-guides/canvas-v9-upgrade-guide"
        }}>{`Canvas v9 Upgrade Guide`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/help/upgrade-guides/canvas-kit-v8-upgrade-guide"
        }}>{`Canvas Kit v8 Upgrade Guide`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/help/upgrade-guides/canvas-kit-v7-upgrade-guide"
        }}>{`Canvas Kit v7 Upgrade Guide`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/help/upgrade-guides/canvas-kit-v6-upgrade-guide"
        }}>{`Canvas Kit v6 Upgrade Guide`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/help/upgrade-guides/canvas-kit-v5-upgrade-guide"
        }}>{`Canvas Kit v5 Upgrade Guide`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/help/upgrade-guides/canvas-kit-v4-upgrade-guide"
        }}>{`Canvas Kit v4 Upgrade Guide`}</a></li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      